import React, { useState, useEffect, useMemo, useCallback } from "react";
import { addLocaleData, IntlProvider } from "react-intl";
import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import PrivateRoute from "./privateRoute"
import { Helmet } from "react-helmet";
import queryString from "query-string";
import history from "./history";

// Engage Settings, Services & Util Imports:
import AppSettings from "../../appsettings";
import { getInitializedUserManager, renewToken } from "../../services/authenticationService";
import {
  getLocale,
  setLocale,
} from "../../services/languageTranslationService.js";
import {
  getNitroToken,
  getNitroUserData,
} from "../../services/nitroService";
import isSafari from "../utils/browserDetectors";
import { getCookie, createCookie } from "../utils/cookie";
import isAppleMobileDevice from "../utils/isAppleMobileDevice";
import { getDynamoName } from "../utils/vehicleName.js";
import "../../styles/css/main.css";
import { getCommentsByUser } from "../../services/commentsService";
import { getProfile } from "../../services/profileService";
import { getAnnouncements } from "../../services/notificationService";
import { postMessageToApp } from "../utils/hybridAppHelpers";
import {
  isDesktopView,
  isTabletView,
  isMobileView,
} from "../../screens/utils/getScreenSize";
import { useUserActivity } from './useUserActivity';

// Page Components & Router Imports:
import AppPageRouterComponent from "../appPage/appPage-router";
import ArticleComponent from "../article/article.component";
import AudioMultimediaRouterComponent from "../audioMultimedia/audioMultimediaRouter/audioMultimediaRouter.component";
import BackToMission from "../../components/molecules/BackToMission";
import BeyondZero from "../beyondZero/beyondZero";
import CertificationRouterComponent from "../certification/certificationRouter.component";
import ContactUs from "../contactUs/contactUs";
import DealershipListComponent from "../dealershipList/dealershipList.component";
import Display404NotFound from "../../components/atoms/404NotFound";
import EBrochuresComponent from "../eBrochures/eBrochures";
import { EngageLiveRouterComponent } from "../engageLive/engageLiveRouter.component";
import EngageXPRouterComponent from "../engageXP/engageXPRouter";
import FeatureLookupRouterComponent from "../featureLookup/featureLookupRouter.component";
import FooterComponent from "../footer/footer.component";
import GlossaryRouterComponent from "../glossary/glossaryRouter.component";
import HomePageComponent from "../homepage/homepage.component";
import { MiscComponent } from "../misc/misc.component";
import MobilityLinkOutComponent from "../mobilityLinkOut/mobilityLinkOut.component";
import News from "../news/news";
import VPRouter from "../VPRouter/VPRouter";
import RecallsAndSafetyRouter from "../recalls-and-safety/recalls-and-safety-router";
import ResourcesComponent from "../resources/resources.component";
import RetailSolutionsRedirectComponent from "../retailSolutionsRedirect.component";
import SafetyLanding from "../safety/safetyLanding";
import SearchBarComponent from "../searchBar/searchBar.component";
import ServiceConnectRouterComponent from "../service-connect/service-connect-router";
import { SkillsComponent } from "../skills/skills.component";
import SmartPathRouterComponent from "../smartPath/smartpath-router.component";
import { SourcebooksComponent } from "../sourcebooks/sourcebooks.component";
import StarSafetySystemRouterComponent from "../star-safety-system/star-safety-system-router";
import TCUVRouterComponent from "../tcuv/tcuvRouter/tcuvRouter.component";
import TopNav from "../topNav/topNav";
import TowingPayloadComponent from "../towingPayload/towingPayloadRouter.component";
import TSSRouterComponent from "../toyotaSafetySense/tss-router";
import { VinLookup } from "../vinLookup/vinLookup";

import { PopupTooltipContext } from "../../components/contexts/popupTooltipContext";
import { NotificationContext } from "../../components/contexts/notificationContext";
import { NitroContext } from "../../components/contexts/nitroContext";
import { WindowSize } from "../../components/contexts/windowSizeContext";
import { FeatureFlagContext } from "../../components/contexts/featureFlagContext";
import { VehiclePageIsPrint } from "../../components/contexts/vehiclePagePrintContext.js";
import ScrollButton from "../../components/molecules/scrollButton";
import { getSharedApiKey } from "../../services/sharedApiService";
import Callback from "../../components/auth/callback";
import OIDCLogin from '../../components/auth/loginService';
import { UserManagerContext } from "../../components/contexts/userManagerContext";
import OIDCLogout from '../../components/auth/logoutService';
import LogoutCallback from "../../components/auth/logoutCallback";
import ProfilePage from "../profilePage/profilePage";

import getMisc from "../../services/miscService";
import { printableDocumentEvents } from "../../utils/printableUtils.js";

addLocaleData([...en, ...es]);

function App() {
  const [dealerCode, setDealerCode] = useState("");
  const [engine, setEngine] = useState("");
  const [hideContent, setHideContent] = useState(false);
  const [isUserDataSet, setIsUserDataSet] = useState(false);
  const [locale, setAppLocale] = useState(getLocale());
  const [featSpec, setFeatSpec] = useState("");
  const [notificationHeight, setNotificationHeight] = useState("");
  const [nitroToken, setNitroToken] = useState("");
  const [nitroLoadingState, setNitroLoadingState] = useState(false);
  const [nitroTokenCalled, setNitroTokenCalled] = useState(false);
  const [model, setModel] = useState("");
  const [trim, setTrim] = useState("");
  const [userID, setUserID] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [visiblePopupTooltip, setVisiblePopupTooltip] = useState(null);
  const [notificationCount, setNotificationCount] = useState({
    unreadReplies: 0,
    unreadAnnouncements: 0,
  });
  const [nitroUserXPLevel, setNitroUserXPLevel] = useState();
  const [resizing, setResizing] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [device, setDevice] = useState(null);
  const [sharedApiKey, setSharedApiKey] = useState("");
  const [userManager, setUserManager] = useState(null);
  const [isPrint, setIsPrint] = useState(false);
  const [featureFlags, setFeatureFlags] = useState();
  const lastActivity = useUserActivity();

  const printableUtils = new printableDocumentEvents();

  const lang = getCookie("lang");
  if (lang) {
    setLocale(lang);
  }
  
  const checkCurrentTooltipMatch = (currentTooltip) =>
    visiblePopupTooltip == currentTooltip;

  const getURLYear = (currentURL) => {
    let yearURL = currentURL.match(/\/20\d\d/);
    if (yearURL) {
      yearURL = yearURL[0].replace(/\//g, "");
    }
    return yearURL;
  };

  const getURLModel = (currentURL) => {
    const path = currentURL.split("/");
    const modelURL = path[4];
    return getDynamoName(modelURL);
  };

  const initializeUserManager = async () => {
    const initializedUserManager = await getInitializedUserManager();
    setUserManager(initializedUserManager);
  };

  const handleRenewToken = useCallback(async () => {
    await renewToken(userManager, lastActivity.current);
  }, [userManager, lastActivity]);

  const handleTokenExpired = useCallback(() => {
    window.location.href = '/logout?source=auto&status=idle';
  }, []);

  useEffect(() => {
    if (userManager) {
      userManager.events.addAccessTokenExpiring(handleRenewToken);
      userManager.events.addAccessTokenExpired(handleTokenExpired);
    }
    return () => {
      if (userManager) {
        userManager.events.removeAccessTokenExpiring(handleRenewToken);
        userManager.events.removeAccessTokenExpired(handleTokenExpired);
      }
    };
  }, [userManager, handleRenewToken, handleTokenExpired]);

  const initializeYearAndModel = () => {
    const currentURL = window.location.href;
    const yearURL = getURLYear(currentURL);
    const modelURL = getURLModel(currentURL);
    const parsedQuery = queryString.parse(location.search);

    yearURL && setYear(yearURL);
    modelURL && setModel(modelURL);
    if (parsedQuery.engine && parsedQuery.trim) {
      setEngine(parsedQuery.engine);
      setTrim(parsedQuery.trim);
    }
    parsedQuery.featureSpec && setFeatSpec(parsedQuery.featureSpec);
  };

  const loadNitro = (forceNitroReload = false) => {
    if (localStorage.getItem("spin_id") && (nitroTokenCalled == false || forceNitroReload)) {
      setNitroToken("");
      setNitroTokenCalled(true);
      getNitroToken().then((nitroToken) => {
        setNitroToken(nitroToken);
      });

      if (localStorage.getItem("spin_id")) {
        getNitroUserData().then((response) => {
          setNitroUserXPLevel(response);
        });
      }
    }
  };

  const loadSharedApiKey = () => {
    getSharedApiKey().then((response) => {
      setSharedApiKey(response);
    });
  };
  postMessageToApp("SharedApiKey", sharedApiKey);

  const preventSafariCachingOnBackButton = () => {
    if (isSafari() || isAppleMobileDevice()) {
      (function () {
        window.onpageshow = function (event) {
          if (event.persisted) {
            window.location.reload();
          }
        };
      })();
    }
  };

  const setCurrentPopupTooltip = (i) => {
    visiblePopupTooltip == i
      ? setVisiblePopupTooltip(null)
      : setVisiblePopupTooltip(i);
  };

  const setNotificationPadding = () => {
    const notificationsContainers = document.getElementsByClassName(
      "alert-banner-container"
    );
    const totalMarginTop = Array.from(notificationsContainers).reduce(
      (acc, container) => {
        return (acc += container.offsetHeight);
      },
      0
    );

    setNotificationHeight(totalMarginTop);
  };

  // should run every minute as well as every time a notification becomes read in their respective components
  // call after sending update to dynamo to get latest data and not rely on local tracking
  const getNotificationCount = async () => {
    let notifications = {
      unreadReplies: notificationCount.unreadReplies,
      unreadAnnouncements: notificationCount.unreadAnnouncements,
    };

    await getCommentsByUser(localStorage.getItem("spin_id")).then((data) => {
      notifications.unreadReplies = data
        ?.flatMap((i) => i.reply)
        .filter((i) => i?.isUnread).length;
    });

    await getAnnouncements(localStorage.getItem("spin_id")).then((data) => {
      notifications.unreadAnnouncements = data.filter((i) => !i.isRead).length;
    });
    postMessageToApp("NotificationCount", notifications);
    setNotificationCount(notifications);
  };

  const getProfilePictureUrl = () => {
    if (!localStorage.pictureUrl) {
      getProfile().then((userData) => {
        localStorage.setItem(
          "pictureUrl",
          userData.pictureUrl ? userData.pictureUrl : ""
        );
      });
    }
  };

  const setCorpUserCookie = () => {
    let engageCorpUserValue;
    const personType = localStorage?.getItem("personType")?.toLowerCase();
    const regionCode = localStorage?.getItem("region_code")?.toString();

    switch (personType?.toLowerCase()){
      case 'associates':
      case 'non-associates':
      case 'affiliates':
        engageCorpUserValue = 'tmna';
        break;
      case 'private-distributors':
      case 'dealer-user':
        if (regionCode === '60' || regionCode === '600'){
          engageCorpUserValue = 'gst';
        }
        else if (regionCode === '50' || regionCode === '500') {
          engageCorpUserValue = 'set';
        }
        else {
          engageCorpUserValue = 'direct';
        }
        break;
      case 'external':
        engageCorpUserValue = 'direct'
        break;
      default:
        engageCorpUserValue = 'unknown';
        break;
    }

    if (engageCorpUserValue != 'unknown') {
      createCookie("engageCorpUser", engageCorpUserValue, 365, true);
    }
    

  };

  useEffect(() => {
    loadNitro();
  });

  useEffect(() => {
    clearTimeout(window.resizedFinished);
    setResizing(true);
    window.resizedFinished = setTimeout(() => {
      setResizing(false);
    }, 250);
  }, [windowWidth]);

  const updateDevice = () => {
    setWindowWidth(window.innerWidth);
    if (isDesktopView(window.innerWidth)) {
      setDevice("desktop");
    } else if (isTabletView(window.innerWidth)) {
      setDevice("tablet");
    } else if (isMobileView(window.innerWidth)) {
      setDevice("mobile");
    }
  };

  const getDynamoFeatureFlags = () => {
    getMisc("featureFlags")
      .then((data) => {
        setFeatureFlags(data.featureFlags);
      })
      .catch((error) => {
        console.log(`ERROR: failed to get dynamo feature flags. ${error}`);
      });
  }
  
  const setPlatform = () => {
    const platform = window.ReactNativeWebView ? 'App' : 'Web';
    createCookie("engagePlatform", platform, 365, true);
  };

  useEffect(() => {
    setPlatform();
    initializeUserManager();
    initializeYearAndModel();
    getProfilePictureUrl();
    preventSafariCachingOnBackButton();
    getNotificationCount();
    loadNitro();
    loadSharedApiKey();
    // setInterval(async () => await getNotificationCount(),  5 * 1000);
    getDynamoFeatureFlags();
    updateDevice();
    window.addEventListener("resize", () => updateDevice());

    // PRINTABLE
    printableUtils.subscribe()
    
    return () => {
      window.removeEventListener("resize", () => updateDevice());
      printableUtils.unsubscribe();
    }
  }, []);

  useEffect(() => {
    setCorpUserCookie();
  }, [localStorage?.getItem("isCorporate"), localStorage?.getItem("region_code")]);

  const nitroContextValues = useMemo(() => ({
    setNitroUserXPLevel,
    nitroUserXPLevel,
    loadNitro,
    nitroToken,
    setNitroLoadingState,
    nitroLoadingState,
  }), [nitroUserXPLevel, nitroToken, nitroLoadingState]);

  return (
    <>
      <Helmet>
        {process.env.REACT_APP_ENVIRONMENT === "prod" ? null : (
          <meta name="robots" content="noindex,nofollow" />
        )}
      </Helmet>
      <Router history={history}>
        <IntlProvider
          locale={locale}
          defaultLocale="en"
          messages={require(`../../locales/${locale}.json`)}
        >
          <div className={`main ${nitroLoadingState ? 'loading': ''}`}>
            <div id="modalPortal" />
            <VehiclePageIsPrint.Provider value={{ isPrint, setIsPrint }}>
            <FeatureFlagContext.Provider value={{ featureFlags }}>
            <WindowSize.Provider value={{ resizing, device }}>
              <UserManagerContext.Provider value={{ userManager, setUserManager }}>
                <NotificationContext.Provider
                  value={{
                    setNotificationCount,
                    notificationCount,
                    getNotificationCount,
                  }}
                >
                  <NitroContext.Provider
                    value={nitroContextValues}
                  >
                    <div className="off-canvas-wrapper">
                      <div
                        className="off-canvas-wrapper-inner"
                        data-off-canvas-wrapper
                      >
                      <TopNav
                        userId={userID}
                        userImage={localStorage.pictureUrl}
                        updateHideContent={setHideContent}
                        removeNotificationPadding={setNotificationPadding}
                        setNotificationPadding={setNotificationPadding}
                      />
                      <div
                        className={`off-canvas-content ${
                          hideContent ? "hidden" : ""
                        }`}
                        data-off-canvas-content
                      >
                        <div className="row fixedWidthColumn">
                            <div
                              id={"body-content"}
                              style={{
                                ...(window.ReactNativeWebView && {
                                  paddingTop: 0,
                                }),
                                marginTop: notificationHeight + "px",
                              }}
                            >
                              <PopupTooltipContext.Provider
                                value={{
                                  visiblePopupTooltip,
                                  setVisiblePopupTooltip,
                                  setCurrentPopupTooltip,
                                  checkCurrentTooltipMatch,
                                }}
                              >
                                { // Remove before release
                                  (window.location.href.includes('nonprod') || window.location.href.includes('localhost')) 
                                  && <div>Welcome Back, Commander!</div>
                                }
                                <Switch>
                                  <PrivateRoute exact path="/">
                                    <Redirect to="/home" />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/home`}
                                    component={HomePageComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/app/:page?`}
                                    component={AppPageRouterComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/article`}
                                    component={ArticleComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/articles/:articleId`}
                                    component={ArticleComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/audioMultimedia/:page?`}
                                    component={AudioMultimediaRouterComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/certification/:page?`}
                                    component={CertificationRouterComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/contact`}
                                    component={ContactUs}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/dealershipList/:dealerCode`}
                                    component={DealershipListComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/eBrochures`}
                                    component={EBrochuresComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/engageLive/*`}
                                    component={EngageLiveRouterComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/engageResources/:resourceType/:recordType`}
                                    component={SkillsComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/engage-xp/:page?/:missionId?/:quizId?`}
                                    component={EngageXPRouterComponent}
                                  />
                                  {AppSettings.FeatureFlags
                                    .showFeatureLookup && (
                                    <PrivateRoute
                                      path={`${AppSettings.BasePath}/featureLookup`}
                                      component={FeatureLookupRouterComponent}
                                    />
                                  )}
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/hybrid/:page?`}
                                    component={BeyondZero}
                                  />
                                  {AppSettings.FeatureFlags.showGlossary && (
                                    <PrivateRoute
                                      path={`${AppSettings.BasePath}/glossary/:page?`}
                                      component={GlossaryRouterComponent}
                                    />
                                  )}
                                  <PrivateRoute path={`${AppSettings.BasePath}/ldrs`}>
                                    <RetailSolutionsRedirectComponent
                                      redirectPath={"ldrs"}
                                    />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/mobilityCenter`}
                                  >
                                    <MobilityLinkOutComponent
                                      dealerCode={dealerCode}
                                      spinId={userID}
                                    />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/myaccount/:page?`}
                                  >
                                    <ProfilePage></ProfilePage>  
                                  </PrivateRoute>
                                  {AppSettings.FeatureFlags.showEngageNews && (
                                    <PrivateRoute
                                      path={`${AppSettings.BasePath}/news`}
                                      component={News}
                                    />
                                  )}
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/product/:model/:year?/:page?/:subPage?`}
                                    component={VPRouter}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/recallsAndSafety/:page?`}
                                    component={RecallsAndSafetyRouter}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/resources`}
                                    component={ResourcesComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/safety`}
                                    component={SafetyLanding}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/search/:searchString?`}
                                    component={SearchBarComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/serviceConnect/:page?`}
                                    component={ServiceConnectRouterComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/smartPath/:page?`}
                                    component={SmartPathRouterComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/sourcebooks`}
                                    component={SourcebooksComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/starSafetySystem/:page?`}
                                    component={StarSafetySystemRouterComponent}
                                  />
                                  <PrivateRoute path={`${AppSettings.BasePath}/tdrs`}>
                                    <RetailSolutionsRedirectComponent
                                      redirectPath={"tdrs"}
                                    />
                                  </PrivateRoute>
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/termsConditions`}
                                    component={() => (
                                      <MiscComponent pageName="termsConditions" />
                                    )}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/tcuv/:page?`}
                                    component={TCUVRouterComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/towingPayload/:model?/:year?/:code?`}
                                    component={TowingPayloadComponent}
                                  />
                                  <PrivateRoute
                                    path={`${AppSettings.BasePath}/toyotaSafetySense/:page?`}
                                    component={TSSRouterComponent}
                                  />
                                  {AppSettings.FeatureFlags.vinLookup && (
                                    <PrivateRoute
                                      path={`${AppSettings.BasePath}/vinLookup`}
                                      component={VinLookup}
                                    />
                                  )}
                                  <Route  
                                    path="/auth/callback"
                                    component={Callback}
                                  />
                                  <Route
                                    path={`/login`}
                                    component={ OIDCLogin }
                                  />
                                  <Route path="/404">
                                    <Display404NotFound display />
                                  </Route>
                                  <Route 
                                    exact path={`${AppSettings.BasePath}/logout`}
                                    component={OIDCLogout}
                                  />
                                  <Route 
                                    path='/logout/callback'
                                    component={LogoutCallback}
                                  />
                                
                              
                                  {/* If none of the above routes are hit, display the 404 component in place without rerouting/losing the url */}
                                  <Route>
                                    <Display404NotFound display />
                                  </Route>
                                </Switch>
                              </PopupTooltipContext.Provider>
                            </div>
                          <div className="universalScroll">
                            <ScrollButton
                              imagePath={
                                AppSettings.AWSImgRepo.resources + "en/"
                              }
                              url={window.location.href}
                            />
                          </div>
                          <BackToMission/>
                          <FooterComponent />
                          <img
                            className="engage-printable-logo"
                            src={`${AppSettings.AWSImgRepo.resources}en/topNav_engage_logo.png`}
                          />
                        </div>
                      </div>
                    </div>
                    </div>
                  </NitroContext.Provider>
                </NotificationContext.Provider>
              </UserManagerContext.Provider>
            </WindowSize.Provider>
            </FeatureFlagContext.Provider>
            </VehiclePageIsPrint.Provider>
          </div>
        </IntlProvider>
      </Router>
    </>
  );
}

export default App;
